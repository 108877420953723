<template>
  <div class="d-flex align-items-center">
    <UserAvatar :user="user"></UserAvatar>
    <div class="ms-3">{{ fullName }}</div>
  </div>
</template>

<script>
export default {
  name: 'UserProfile',
  components: {
    UserAvatar: () => import('@/components/UserAvatar'),
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    fullName() {
      const { firstName, middleName, lastName } = this.user;
      return [firstName, middleName || '', lastName].join(' ');
    },
  },
};
</script>
